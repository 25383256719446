import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module'
import { CountToModule } from 'angular-count-to';
import { AngularTiltModule } from 'angular-tilt';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

// Enterprice SASS Layout
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';
import { EnterpriceSassNavComponent } from './enterprice-sass/enterprice-sass-nav/enterprice-sass-nav.component';
import { EnterpriceSassHeaderComponent } from './enterprice-sass/enterprice-sass-header/enterprice-sass-header.component';
import { EnterpriceSassFeatureComponent } from './enterprice-sass/enterprice-sass-feature/enterprice-sass-feature.component';
import { EnterpriceSassWorkComponent } from './enterprice-sass/enterprice-sass-work/enterprice-sass-work.component';
import { EnterpriceSassPriceComponent } from './enterprice-sass/enterprice-sass-price/enterprice-sass-price.component';
import { EnterpriceSassFaqComponent } from './enterprice-sass/enterprice-sass-faq/enterprice-sass-faq.component';
import { EnterpriceSassCopyrightComponent } from './enterprice-sass/enterprice-sass-copyright/enterprice-sass-copyright.component';
import { EnterpriceSassBuildComponent } from './enterprice-sass/enterprice-sass-build/enterprice-sass-build.component';
import { EnterpriceSassContactComponent } from './enterprice-sass/enterprice-sass-contact/enterprice-sass-contact.component';
import { EnterpriceSassFooterComponent } from './enterprice-sass/enterprice-sass-footer/enterprice-sass-footer.component';
import { EnterpriceSassTermComponent } from './terms/enterprice-sass-terms/enterprice-sass-term.component';
import { CookieComponent } from './terms/cookie/cookie.component';

import { NgxMasonryModule } from 'ngx-masonry';
import { EmailApiService } from './services/email-api.service';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
  declarations: [
    EnterpriceSassComponent,
    EnterpriceSassNavComponent,
    EnterpriceSassHeaderComponent,
    EnterpriceSassFeatureComponent,
    EnterpriceSassWorkComponent,
    EnterpriceSassPriceComponent,
    EnterpriceSassFaqComponent,
    EnterpriceSassCopyrightComponent,
    EnterpriceSassBuildComponent,
    EnterpriceSassFooterComponent,
    EnterpriceSassContactComponent,
    EnterpriceSassTermComponent,
    CookieComponent
  ],
  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgxMasonryModule
  ],
  exports: [],
  providers: [
    EmailApiService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})

export class LayoutsModule { }
