import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { EmailMessageModel } from 'src/app/shared/models/email-message.model';

import { EmailApiService } from '../../services/email-api.service';

@Component({
  selector: 'app-enterprice-sass-contact',
  templateUrl: './enterprice-sass-contact.component.html',
  styleUrls: ['./enterprice-sass-contact.component.scss']
})
export class EnterpriceSassContactComponent implements OnInit {

  isSent: boolean;
  contactForm: FormGroup;
  message: EmailMessageModel = {} as EmailMessageModel;

  constructor(
    private readonly fb: FormBuilder,
    private readonly emailApiService: EmailApiService) {
      this.initializeForm();
    }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (!this.contactForm.valid) {
      return;
    }

    this.emailApiService.sendEmail(this.message).subscribe(
      response => { this.isSent = true; },
      error => {}
    );
  }

  closeAlert(): void {
    this.isSent = false;
  }

  private initializeForm(): void {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      to: ['', [Validators.required, Validators.email]],
      body: ['', Validators.required]
    });
  }
}
