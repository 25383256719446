import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-feature',
  templateUrl: './enterprice-sass-feature.component.html',
  styleUrls: ['./enterprice-sass-feature.component.scss']
})
export class EnterpriceSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  features= [
    {
      title:'Digitalni Unos',
      subTitle: 'Evidencija',
      description: 'Pomoću naše aplikacije lagano i brzo unesite podatke potrebne za ispunjavanje postojećih HACCP evidencija',
      img: 'assets/images/saas1/feature/1.png',
    },
    {
      title:'Pregled',
      subTitle: 'i Kontrola Unosa',
      description: 'Vlasnik/voditelj objekta može pregledati sve evidencije te ih ovjeriti, ali će i biti obaviješten ukoliko određena evidencija nije ispunjena ili je unesena vrijednost koja ne odgovara sigurnosnim standardima',
      img: 'assets/images/saas1/feature/2.png',
    },
    {
      title:'Online',
      subTitle: 'Pohrana Podataka',
      description: 'Sve evidencije koje generirate će biti pohranjene na naš server i dostupne u bilo kojem trenutku za pregled ili ispis',
      img: 'assets/images/saas1/feature/3.png',
    },
    {
      title:'Kontrola',
      subTitle: 'Zaposlenika i Opreme',
      description: 'Na jednom mjestu vodite kontrolu sanitarnih iskaznica, higijenskih minimuma, atestova opreme i ostalih potrebnih dozvola uz definiranje roka trajanja određenih dozvola i alarmiranja u slučaju isteka',
      img: 'assets/images/saas1/feature/4.png',
    },
    {
      title:'Zakonska',
      subTitle: 'Regulativa',
      description: 'Nakon unosa podataka aplikacija generira PDF dokument koji je istovjetan evidencijama koje su definirane Pravilnikom (NN 68/2015) te je kao takav prihvaćen od strane nadležnog ministarstva',
      img: 'assets/images/saas1/feature/5.png',
    },
    {
      title:'Kontrola',
      subTitle: 'i Transparentnost',
      description: 'Pomoću upravljačke ploče voditelj objekta može putem svog računala pristupiti svim ispunjenim evidencijama, ovjeriti i arhivirati evidencije, pregledati i po potrebi poslati dokument u PDF obliku ili ga putem printera dobiti u tiskanom izdanju',
      img: 'assets/images/saas1/feature/6.png',
    }
  ]
}
