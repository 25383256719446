<!--footer start-->
<footer class="saas1 footer2" style="padding: 15px 0;">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="logo-sec">
                    <div class="footer-contant">
                        <!-- <img src="assets/images/logo/3.png" alt="" class="img-fluid footer-logo"> -->
                        <div class="footer-para">
                            <h6 class="text-white para-address"><b>MVT Solutions Group d.o.o.</b></h6>
                            <h6 class="text-white para-address">Podolje 11A, 10000 Zagreb</h6>
                            <h6 class="text-white para-address"><b>OIB:</b> 85300439344</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="https://www.facebook.com/digitalhaccpmanager" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>                           
                            <li class="footer-social-list">
                                <a href="tel:+385998028244" title="+385 (0) 99 8028 244"><i class="fa fa-phone" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="mailto:info@dhm.hr" title="info@dhm.hr"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                            </li>                            
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-contant">
                    <div>
                        <ul class="footer-lists">                               
                            <li class="">
                                <a id="google-play-link-footer"  href='https://play.google.com/store/apps/details?id=com.dhm&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
                                    <img style="height: 60px;" alt='Dostupno na usluzi Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/hr_badge_web_generic.png'/>
                                </a> 
                            </li>
                            <li class="">
                                <a href='https://apps.apple.com/app/digital-haccp-manager/id6466750542' target="_blank">
                                    <img style="height: 52px;" alt='Dostupno na usluzi App Store' src='assets/images/apple-app-store-badge.svg'/>
                                </a> 
                            </li>                                            
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-contant" style="text-align: center;"> <!-- Center alignment -->
                    <div class="footer-para">
                        <h6 class="text-white" style="text-transform: none;">DHM je dio MVT Solutions Group d.o.o.</h6> <!-- Normal capitalization -->
                    </div>
                    <div>
                        <ul class="footer-lists" style="padding: 0; list-style: none;"> <!-- Remove bullets and padding for direct centering -->
                            <li style="list-style-type: none;"> <!-- Ensure no list bullet -->
                                <div id="part_of_mvt" style="display: inline-block;"> <!-- Inline-block for centering the image -->
                                    <img style="width: 70%;" src="assets/images/partners/mvt_logo.png" alt="title-logo" class="img-fluid">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
             <!-- <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Support</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">For Freelancers</a></li>
                            <li class=""><a href="#">For Companies</a></li>
                            <li class=""><a href="#">For Business</a></li>
                            <li class=""><a href="#">General Help</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
