<!-- header section Start-->
<section class="saas1 header" id="home">
    <div class="saas1-header bg header8-content"  [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <div class="d-flex">
                                    <h1 class="header-text-extend">
                                        <span class="theme-color">D</span>igital <span class="theme-color">H</span>ACCP <span class="theme-color">M</span>anager
                                    </h1>
                                    <div class="center-content slider-logo">
                                        <img src="assets/images/saas1/slider-logo.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="header-sub-text">
                                <h4 class="text-white">Digitalni način vođenja<span> HACCP</span> evidencija</h4>
                            </div>
                            <div class="header-sub-text">
                                <h6>
                                    BRZO<span class="theme-color">&nbsp;<img src="assets/images/saas1/build-.png" alt="plan-icon">&nbsp;</span>JEDNOSTAVNO<span class="theme-color">&nbsp;<img src="assets/images/saas1/build-.png" alt="plan-icon">&nbsp;</span>SIGURNO
                                </h6>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li>                                        
                                        <a id="google-play-button" (click)="navigateToGooglePlay()" target="_blank">
                                            <img style="height: 60px; margin: -5px;" alt='Dostupno na usluzi Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/hr_badge_web_generic.png'/>
                                        </a>                                    
                                    </li>
                                    <li>                                        
                                        <a id="google-play-button" (click)="navigateToAppStore()" target="_blank">
                                            <img style="height: 49px;" alt='Dostupno na usluzi App Store' src='/assets/images/apple-app-store-badge.svg'/>
                                        </a>                                    
                                    </li>
                                    <!-- <li>
                                        <a class="btn btn-default primary-btn transparent" href="#plan">Pretplate</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="center-text slider-banner">
                        <img src="assets/images/saas1/web-tab+smartphone.png" alt="slid-banner">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="">
    <img src="assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="">
</section>
<!-- header section end-->