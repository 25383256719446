<!-- faq section -->
<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text">Česta pitanja</h3>
                        <h6></h6>
                        <div class="accordion faq" id="accordionExample">
                            <div class="card">
                                <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                    <ngb-panel id="static-1">
                                        <ng-template ngbPanelTitle>  
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Je li ovaj način ispunjavanja HACCP evidencija u skladu sa zakonom i hoću li imati problema sa inspekcijama?
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Da, u skladu je sa zakonom. 
                                            Zakonu o hrani (»Narodne novine«, broj 81/2013 i 14/2014) ne definira da se evidencije moraju voditi na papiru i ispunjavati olovkom već je bitno da se vode.
                                            Također zbog prednosti same aplikacije da vas upozori ukoliko neka provjera nije obavljena te samim time i evidencija nije ispunjena svi poslovi oko HACCP sistema su puno sigurniji i smanjen je rizik od potencijalne opasnosti na minimum te je izbačen ljudski faktor. 
                                            Aplikacija daje podršku u kontroli i osobni je asistent u procesu ispunjavanja HACCP standarda.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-2">
                                        <ng-template ngbPanelTitle>                                                            
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Mogu li svi unutar nekog objekta mogu koristiti aplikaciju?       
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Naravno da mogu. 
                                            Svi zaposlenici koji imaju vlastiti ili službeni mobitel ili bilo koji drugi pametni uređaj mogu pristupiti aplikaciji nakon što mu vlasnik/voditelj objekta postavi početni profil. 
                                            Također je moguće i određenom zaposleniku omogučiti ili blokirati pristup određenoj evidenciji ukoliko tako voditelj objekta tako odluči.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-3">
                                        <ng-template ngbPanelTitle>
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Jesu li podaci tj. evidencije koje sam ispunio sigurni u aplikaciji?
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Jesu. 
                                            Svi kreirani PDF dokumenti kao i svi ostali podaci unutar same aplikacije se čuvaju na zaštićenom serveru. 
                                            Također nakon proteka svake godine će te dobiti sve evidencije koje ste kreirali pa ih možete čuvati na koji god način želite (cloud servis, CD, računalo,...)
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-4">
                                        <ng-template ngbPanelTitle>
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Što ako slučajno izgubim ili mi se pokvari mobitel, gubim li sve podatke?
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Ne, svi kreirani dokumenti se čuvaju na serveru i kad nabavite novi mobitel i pristupite svom profilu sa svojim korisničkim podacima imate pristup svemu što ste radili na starom uređaju.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-5">
                                        <ng-template ngbPanelTitle>
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Što ako nemam interneta u objektu?
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Ukoliko dođe do pomanjkanja internet veze aplikacija kao takva neće raditi iz sigurnosnih razloga jer je moguć unos podataka od strane više osoba pa je moguće da evidencije ne budu dobro ispunjenje. 
                                            Dok se internet veza ne uspostavi preporuča se ručni unos te naknadni unos kroz aplikaciju.
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-6">
                                        <ng-template ngbPanelTitle>
                                            <a>
                                                <div class="fa fa-angle-right rotate"></div>
                                            </a>
                                            Inspekcija želi da joj izlistam određenu evidenciju, mogu li to?
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            Apsolutno. 
                                            Obzirom da aplikacija kreira klasičan PDF dokument njega možete izlistati na printeru ili poslati e-mailom u inspekciju ukoliko im je potrebna fizička kopija kod nadzora.
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                         </div>
                        <h6 class="link faq-h6">Još uvijek imate pitanja? Obratite se na: <a>info@dhm.hr</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end faq section -->
