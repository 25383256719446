<a  class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide" >
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <!-- 1st Level Menu -->

        
        <li *ngFor="let menuItem of menuItems" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <!-- <a [routerLink]="['home']" fragment="plan">
                test
            </a> -->
            <!-- Sub -->
            <a [routerLink]="['home']" fragment="{{menuItem.path}}" *ngIf="menuItem.type === 'sub'" (click)="setActive(menuItem.title)">
                {{menuItem.title}}
            </a>
            <!-- Link -->
            <a routerLink="/" routerLinkActive="active" class="dropdown" *ngIf="menuItem.type === 'link'">
                {{menuItem.title}}
            </a>
            <!-- External Link -->
            <a  href="{{menuItem.path}}" class="dropdown" *ngIf="menuItem.type === 'extLink'">
                {{menuItem.title}}
            </a>
            <!-- External Tab Link -->
            <a  href="javascript:void(0)" *ngIf="menuItem.type === 'extTabLink'" (click)="setActive(menuItem.title)">
                {{menuItem.title}}
            </a>
        </li>
<!--        <li>-->
<!--            <a class="btn btn-default primary-btn" href="https://manage.dhm.hr/">PRIJAVA</a>-->
<!--        </li>-->
        </ul>  
</div>
  
