import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmailMessageModel } from '../../shared/models/email-message.model';
import { BaseApiService } from '../../shared/service/base-api.service';

@Injectable()
export class EmailApiService extends BaseApiService {
    constructor(readonly http: HttpClient) {
        super(http);
    }

    sendEmail(message: EmailMessageModel): Observable<any> {
        const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Accept', '*/*')
        .set('Access-Control-Allow-Origin', '*');
        return this.post<any>(`https://amicus-felix.crohost.net/api/email`, message, headers);
    }

    get baseUrl(): string {
        return `${environment.baseApi}/email`;
    }
}
