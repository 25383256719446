import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enterprice-sass-term',
  templateUrl: './enterprice-sass-term.component.html',
  styleUrls: ['./enterprice-sass-term.component.scss']
})
export class EnterpriceSassTermComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

  back(): void {
    this.router.navigate(['/home']);
  }
}
