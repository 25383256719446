import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {
  public showScroll: boolean;
  public showScrollHeight = 500;
  public hideScrollHeight = 10;

  constructor() { }

  ngOnInit() {}

  onActivate() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
}

@HostListener('window:scroll', [])
    onWindowScroll() {
      if (( window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
        this.showScroll = true;
      } else if (this.showScroll && 
        (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
        this.showScroll = false;
      }
    }
}
