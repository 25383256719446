import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-header',
  templateUrl: './enterprice-sass-header.component.html',
  styleUrls: ['./enterprice-sass-header.component.scss']
})
export class EnterpriceSassHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  navigateToGooglePlay(): void {
    window.open("https://play.google.com/store/apps/details?id=com.dhm&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1");
  }

  navigateToAppStore(): void {
    window.open("https://apps.apple.com/app/digital-haccp-manager/id6466750542");
  }
}
