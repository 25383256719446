<!-- section start -->
<section class="agency blog-sec blog-sidebar single_blog_item">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">                
                <div class="blog-text" >
                    <h6 id="privacy">20.03.2024.</h6>
                    <a href="/terms#privacy">
                        <h3 class="blog-head">Kolačići - Cookie</h3>
                    </a>
                    <div  class="blog-divider"></div>                   
                    <div class="blog-description">  
                        <h5 class="page-header-comment">Obavjest</h5>                     
                        <p>Za pravilan rad i unapređenje bilo koje internetske stranice, pa tako i ove, na vaše računalo pohranjuje se mala količina informacija – kolačića (Cookies). Kako bi se poboljšalo vaše korisničko iskustvo, većina internetskih stranica koristi ovu praksu. O istoj smo vas obavezni obavijestiti i za to zatražiti vaš pristanak.</p>
                        <p>Korištenjem ove internetske stranice pristajete na upotrebu kolačića. Blokiranjem kolačića i dalje možete koristiti ovu stranicu, ali neke njezine mogućnosti biti će onemogućene.</p>
                        
                        <h5 class="page-header-comment">Što su kolačići?</h5>
                        <p>Kolačići su informacije koje se prilikom posjete internetskih stranica pohranjuju na vaše računalo. Obično pohranjuju vaše postavke, postavke internetske stranice, kao što su preferirani jezik ili adresa. Kada se vratite na istu internetsku stranicu internet preglednik šalje natrag kolačiće koji pripadaju toj stranici. Tako stranica prikazuje informacije prilagođene vašim potrebama.</p>
                        <p>Kolačići mogu pohranjivati različite informacije pa čak i osobne podatke, kao što su vaše ime i/ili email adresa, ali one mogu biti pohranjene isključivo ako vi to omogućite. Internetske stranice ne mogu pristupati informacijama niti datotekama na vašem računalu za koje vi niste dali odobrenje. Postavke Internet preglednika možete mijenjati – sami birate prihvaćate li pohranjivanje kolačića ili ne, te želite li pobrisati spremljene kolačiće automatski pri zatvaranju Internet preglednika itd.</p>                       

                        <h5 class="page-header-comment">Kako blokirati kolačiće?</h5>
                        <p>Instaliranje kolačića možete blokirati odgovarajućom postavkom u vašem pregledniku.</p>
                        <p>Napominjemo, u tom slučaju nećete moći koristiti sve funkcije internetske stranice u punom opsegu.</p>
                        <p>Više informacija pronađite na: 
                            <a href="https://www.google.com/intl/en/chrome/privacy/">Chrome</a>&nbsp;
                            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies#w_cookie-settings">Firefox</a>&nbsp;
                            <a href="https://support.microsoft.com/hr-hr/hub/4230784/internet-explorer-help">Internet Explorer</a>&nbsp;
                            <a href="https://www.apple.com/safari/#security">Safari</a><br>
                            Za druge verzije preglednika više informacija potražite na linku: <a style=" text-transform: lowercase;" href="https://www.aboutcookies.org/how-to-control-cookies/">https://www.aboutcookies.org/how-to-control-cookies/</a>
                        </p>

                        <h5 class="page-header-comment">Tipovi kolačića koje pohranjuje vaše računalo</h5>
                        <p>
                            <b>Privremeni kolačići ili kolačići sesije</b> - pomoću njih web mjesta pohranjuju privremene podatke kao što su stavke u košarici internetske trgovine. Uklanjaju se s računala po zatvaranju internet preglednika.<br>
                            <b>Stalni ili spremljeni kolačići</b> - pomoću njih web mjesta pohranjuju informacije, kao što su podaci za prijavu, tako da se ne morate prijavljivati prilikom svakog posjeta određenom mjestu. Ostaju na računalu danima, mjesecima, čak i godinama.<br>
                            <b>Kolačići prve strane</b> - dolaze s web mjesta koje pregledavate, a mogu biti stalni i/ili privremeni.<br>
                            <b>Kolačići treće strane</b> - dolaze s drugih web-mjesta, kao što su reklame koje se nalaze na web mjestu koje pregledavate. Ovi kolačići omogućuju praćenje korištenja Interneta u marketinške svrhe.
                        </p>
                        
                        <h5 class="page-header-comment">Koje kolačiće koristimo?</h5>
                        <p>Ova internetska stranica kositi privremene i trajne kolačiće kako bi bolje razumjeli navike korisnika te vam omogućili kvalitetnije korisničko iskustvo.</p>
                        <p>Koristimo i nekoliko kolačića treće strane, vanjskih servisa koji pohranjuju limitirane kolačiće i služe za normalno funkcioniranje određenih mogućnosti za lakši pristup sadržaju.</p>
                        <p>Za mjerenje posjećenosti koristimo Googel Analytics servis. Za onemogućavanje pohranjivanja njegovih kolačića posjetite: <a style=" text-transform: lowercase;" href="https://tools.google.com/dlpage/gaoptout"></a>https://tools.google.com/dlpage/gaoptout</p>
                    </div>
                </div>
                <div class="booking" style="margin-top:10px">
                    <button class="btn btn-default primary-btn m-0-auto bg-black radius-0 pull-right" (click)="back()"><  Nazad</button>
                </div>                           
            </div>
        </div>
    </div>
</section>
<!-- section end -->