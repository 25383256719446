<!-- build section start -->
<section id="benefits" class="saas1 build-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="build-right-slider">
            <owl-carousel-o [options]="sync2" class="sync2" #owlCar (changed)="owlCar1.to($event.startPosition.toString())" (dragging)="owlCar1.to($event.data.startPosition.toString())"> 
                    <ng-container *ngFor="let slide of buildsync; index as i">
                        <ng-template carouselSlide [id]="i" [dotContent]="owlCar1.to(i)">
                                <img [src]="slide.img" alt="work-slider" class="img-fluid work-slide" style="height: 80vh; margin-left: 10%;">
                        </ng-template>
                    </ng-container>
            </owl-carousel-o>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                    <owl-carousel-o [options]="sync1" class="overflow-hidden sync1"  #owlCar1 (dragging)="owlCar.to($event.data.startPosition.toString())">                    
                        <ng-container  *ngFor="let slide of navsync; index as i">
                        <ng-template carouselSlide [id]="i">
                            <div class="build-box" >
                                <h3 class="build-head text-white"><span class="theme-color">Prednosti</span> aplikacije</h3>
                                
                                <ul>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Ne zahtjeva nikakav fizički prostor za čuvanje podataka pa ste dugoročno oslobođeni od čuvanja registratora 
                                        ispunjenih silnom papirologijom
                                    </li>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Možete voditi razne evidencije vezane za obuku zaposlenika, istek raznih dozvola, iskaznica i atesta,
                                        kao i vođenje evidencija oko održavanja opreme i prostora
                                    </li>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Trošak papira i registratora je nepotreban, dok se sama brzina ispunjavanja evidencija bitno skraćuje
                                        od klasičnog unosa evidencija papirnatim putem
                                    </li>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Ispunjavajte evidencije puno brže nego starinskim papirnatim putem
                                    </li>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Provjerite bilo kada i bilo gdje jesu li vaši zaposlenici ispunili evidencije
                                    </li>
                                    <li>
                                        <img src="assets/images/saas1/build-.png" alt="icon">
                                        Nema više registratora ni masnih papira, svi dokumenti su na sigurnom, sortirani po datumu i 
                                        pristupni za pregled
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                   </ng-container>
                    </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- end build section-->
