import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit {
  public work: any = 'Instalacija';
  constructor() { }

  ngOnInit() {
  }

  openWorkSlide(val){
    this.work = val;
  }

  nav = [
    {
      img: 'assets/images/saas1/tab-icon/installation.png',
      title: 'Instalacija'
    },
    {
      img: 'assets/images/saas1/tab-icon/key-1.png',
      title: 'Korisnički račun'
    },
    {
      img: 'assets/images/saas1/tab-icon/001-tap.png',
      title: 'Dodavanje evidencija'
    },
    {
      img: 'assets/images/saas1/tab-icon/button.png',
      title: 'Arhiva'
    }
  ];

  content = [
    {
      img: 'assets/images/saas1/tab/Initializing.png',
      title: 'Instalacija'
    },
    {
      img: 'assets/images/saas1/tab/Security.png',
      title: 'Korisnički račun'
    },
    {
      img: 'assets/images/saas1/tab/Add-instant.png',
      title: 'Dodavanje evidencija'
    },
    {
      img: 'assets/images/saas1/tab/Configuration.png',
      title: 'Arhiva'
    },
    
  ];

}
