<!-- contact section -->
<section id="contact" class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-white">Adresa</h4>
                    <h6 class="contact-sub-text text-white">Zagreb, Hrvatska</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-white">Kontakt</h4>
                    <h6 class="contact-sub-text text-white">+385 (0) 99 8028 244</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-white">Email</h4>
                    <h6 class="contact-sub-text text-white" style="text-transform: lowercase;">info@dhm.hr</h6>
                </div>
            </div>
        </div>
        
<!--        <form class="theme-form">-->
<!--            <div class="form form-footer p-t-50">-->
<!--                <div *ngIf="isSent" class="alert alert-success dark alert-dismissible fade show text-center" role="alert">-->
<!--                    Vaša poruka je uspješno poslana, odgovorit ćemo uskoro-->
<!--                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()"><span-->
<!--                            aria-hidden="true">×</span></button>-->
<!--                </div>                -->
<!--                <div class="row">                    -->
<!--                    <div class="col-sm-6">-->
<!--                        <div class="form-group">-->
<!--                            <input type="text" placeholder="vaše ime"-->
<!--                                name="subject" autocomplete="off"-->
<!--                                [formControl]="contactForm.controls['subject']" -->
<!--                                [(ngModel)]="message.subject">-->
<!--                            <span *ngIf="contactForm.controls.subject.touched && contactForm.controls.subject.errors?.required" class="text text-danger m-l-15">-->
<!--                                Ime i prezime su potrebni-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-sm-6">-->
<!--                        <div class="form-group">-->
<!--                            <input type="text" placeholder="vaša e-mail adresa"-->
<!--                                name="to" autocomplete="off"-->
<!--                                [formControl]="contactForm.controls['to']" -->
<!--                                [(ngModel)]="message.to">-->
<!--                            <span *ngIf="contactForm.controls.to.touched && contactForm.controls.to.errors?.required" class="text text-danger m-l-15">-->
<!--                                Email adresa je potrebna-->
<!--                            </span>-->
<!--                            <span *ngIf="contactForm.controls.to.touched && contactForm.controls.to.errors?.email" class="text text-danger m-l-15">-->
<!--                                Format email adrese je pogrešan-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->
<!--                        <div class="form-group">-->
<!--                            <textarea class="form-control" rows="6" placeholder="vaša poruka"-->
<!--                                name="body" autocomplete="off"-->
<!--                                [formControl]="contactForm.controls['body']" -->
<!--                                [(ngModel)]="message.body">-->
<!--                            </textarea>-->
<!--                            <span *ngIf="contactForm.controls.body.touched && contactForm.controls.body.errors?.required" class="text text-danger m-l-15">-->
<!--                                Sadržaj poruke je potreban-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="booking">-->
<!--                        <button class="btn btn-default primary-btn m-0-auto bg-black" (click)="onSubmit()">Pošalji</button>-->
<!--                    </div>                    -->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
    </div>
</section>
<!-- end contact section -->
