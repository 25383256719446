<!-- price table -->
<section id="plan" class="saas1 pricing"  [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="col-md-12 text-center">
            <div class="title">
                <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                <div class="main-title">
                    <h2 class="text-white">
                        <span class="theme-color">P</span>retplate
                    </h2>
                </div>
                <hr>                
                <div class="sub-title">
                    <p class="p-padding text-white">Ukoliko imate potrebe za više korisnika ili uređaja prilagoditi ćemo ponudu vašim potrebama.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div id="monthly" class="wrapper-full">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <owl-carousel-o [options]="priceCarouselOptions" class="pricing-slider plan-box">
                        <ng-container>
                            <ng-template carouselSlide *ngFor="let data of price">                                
                                <div class="price-box">
                                    <h3 class="price-heading">{{data.heading}}</h3>
                                    <img src="assets/images/saas1/plan-box.png" alt="pan-line">
                                    <h4 *ngIf="data.priceKn" class="no-weight">
                                        <span>{{data.price}}
                                            <br>{{data.priceKn}}
                                        </span>
                                        <br>mjesečno
                                    </h4>
                                    <p *ngIf="data.priceKn" style="font-size: 8px;">
                                        Obveznik nije u sustavu PDV-a, PDV nije obračunat temeljem čl. 90 st.1 i st.2 Zakona o PDV-u 
                                        <br>(Narodne Novine br. 73/13)
                                        <br>Pretplata se automatski produžuje nakon isteka
                                    </p>
                                    <ul>
                                        <li *ngIf="data.feature01" class="text-center">
                                            <img *ngIf="data.priceKn" src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature01}}
                                        </li>
                                        <li *ngIf="data.feature01_1" class="text-center">
                                            <img *ngIf="data.priceKn" src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature01_1}}
                                        </li>
                                        <li *ngIf="data.feature02">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature02}}
                                        </li>
                                        <li *ngIf="data.feature03">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature03}}
                                        </li>
                                        <li *ngIf="data.feature04">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature04}}
                                        </li>
                                        <li *ngIf="data.feature05">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">                                            
                                            {{data.feature05}}
                                        </li>
                                        <li *ngIf="data.feature06">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature06}}
                                        </li>
                                        <li *ngIf="data.feature07">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature07}}
                                        </li>
                                        <li *ngIf="data.feature08">
                                            <img src="assets/images/saas1/build-.png" alt="plan-icon">
                                            {{data.feature08}}
                                        </li>
                                        <li>                                            
                                        </li>
                                        <li>                                    
                                        </li>
                                        <li>
                                        </li>                                        
                                    </ul>                                    
                                </div>                                
                            </ng-template>                            
                        </ng-container>                        
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end price table -->
