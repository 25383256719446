import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceSassTermComponent } from './layouts/terms/enterprice-sass-terms/enterprice-sass-term.component';
import { EnterpriceSassComponent } from './layouts/enterprice-sass/enterprice-sass.component';
import { CookieComponent } from './layouts/terms/cookie/cookie.component';

export const routes: Routes = [
  {
    path: 'home',
    component: EnterpriceSassComponent,
    data: {title: 'DHM'}
  },
  {
    path: 'terms',
    component: EnterpriceSassTermComponent,
    data: {title: 'DHM'},
  },
  {
    path: 'cookie',
    component: CookieComponent,
    data: {title: 'DHM'},
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
