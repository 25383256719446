import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-price',
  templateUrl: './enterprice-sass-price.component.html',
  styleUrls: ['./enterprice-sass-price.component.scss']
})
export class EnterpriceSassPriceComponent implements OnInit {

  public val:boolean=true;

  constructor() { }

  ngOnInit() {
  }
  
  priceCarouselOptions= {
    items: 2,
    nav: false,
    dots:true,
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        768: {
            items: 3,
            margin: 20
        },
        992: {
            items: 3
        }
    }
  }

  price=[
    { 
      heading:"GODIŠNJA PRETPLATA",
      priceKn:"339,05 kn", 
      price:"45,00 €",      
      feature01:"dostupne sve kategorije rizika",
      feature02:"20 korisničkih profila",
      feature03:"unos 25 uređaja",
      feature04:"unos 25 prostora",
      feature05:"čuvanje podataka na serveru dvije godine dana",
      feature06:"ugovor na godinu dana",
      feature07:"tehnička podrška kod implementacije",
      img: "assets/images/app_landing1/pricing/11.png" 
    },
    { 
      heading:"MJESEČNA PRETPLATA",
      priceKn:"414,40 kn",
      price:"55,00 €",
      feature01:"dostupne sve kategorije rizika",
      feature02:"10 korisničkih profila",
      feature03:"unos 15 uređaja",
      feature04:"unos 15 prostora",
      feature05:"čuvanje podataka na serveru godinu dana",
      feature06:"bez ugovorne obaveze",
      img: "assets/images/app_landing1/pricing/11.png" 
    },
    { 
      heading:"PRILAGOĐENA PRETPLATA",
      
      feature01:"Ukoliko imate potrebu za prilagođenom vrstom pretplate obratite nam se i kreirati ćemo ponudu za Vas.",
      feature01_1:"Ukoliko imate više objakata ili izdvojenu proizvodnju i pripremu također možemo prilagoditi aplikaciju Vašim potrebama.",
      
      img: "assets/images/app_landing1/pricing/11.png" 
    }
  ]

  monthly(){
    this.val=!this.val;
  }

}
