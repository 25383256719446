<!--copyright start-->
<div class="saas1 copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="link-horizontal center-text">
                    <ul>
                        <li>                            
                            <a href="/terms#privacy" class="copyright-text op-text"> Izjava o privatnosti &amp; Zaštiti podataka</a>                        
                        </li>
                        <li>
                            <a href="/terms#conditions" class="copyright-text op-text">Opći uvijeti korištenja</a>                            
                        </li>
                        <li>
                            <a href="assets/files/DHM_Uputstva_za_upotrebu.pdf" class="copyright-text op-text">Upustvo</a>
                        </li>                        
                    </ul>
                </div>
            </div>
            <div class="col-sm-4">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2024 MVT Solutions Group</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright end-->
