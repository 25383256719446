import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
      {
      title: 'Početna', type: 'sub', path: '/', megaMenu: true
      },
      {
         title: 'Info', type: 'sub', path: 'feature', icon: 'map-alt', bookmark: true
      },
      {
         title: 'Prednosti', type: 'sub', path: 'benefits', icon: 'gallery'
      },
      // {
      //    title: 'Roadmap', type: 'sub', path: 'roadmap', icon: 'gallery'
      // },
      // {
      //    title: 'Pretplate', type: 'sub', path: 'plan', icon: 'gallery'
      // },
      {
         title: 'Pitanja', type: 'sub', path: 'faq', icon: 'gallery'
      },
      {
         title: 'Kontakt', type: 'sub', path: 'contact', icon: 'gallery'
      }
   ];

   // Array
   items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
