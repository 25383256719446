import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';

const routes: Routes = [
  {
    path: 'home',
    component: EnterpriceSassComponent,
    data: { title: 'HACCP' }
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
