import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {
  cookieAllow: boolean;

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    const cookieRule = this.cookieService.get('cookie-allow');
    this.cookieAllow = cookieRule ? true : false;
  }

  onAccept(): void {
    this.cookieAllow = true;
    this.cookieService.set('cookie-allow', this.cookieAllow.toString());
  }
}
